.home {
  padding-right: 6rem;
  padding-left: 6rem;
}
.custom-section-svg > svg {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  background-attachment: fixed;
}
.home_headerInfo {
  position: absolute;
  top: 33%;
  opacity: 0;
  left: 10%;
}
.home_headerInfo h1 {
  color: #2a2727;
  font-size: 4rem;
  line-height: 1.1;
  font-weight: bold;
}
.home_headerInfo p {
  color: #2a2727;
  font-size: 20px;
  font-weight: 700;
  margin-top: 40px;
  line-height: 1.6;
  max-width: 750px;
}
@media (max-width: 1100px) {
  .home {
    padding-right: 0rem;
    padding-left: 0rem;
  }
  .home_headerInfo h1 {
    color: #2a2727;
    font-size: 2em;
  }
  .home_headerInfo p {
    font-size: 14px;
  }
  .custom-section-svg > svg {
    height: 450px;
  }
  .home .expertise {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media (max-width: 359px) {
  .home_headerInfo h1 {
    color: #2a2727;
    font-size: 1.8em;
  }
  .home_headerInfo p {
    font-size: 14px;
  }
  .custom-section-svg > svg {
    height: 560px;
  }
}
.expertise {
  padding: 50px 0;
  padding-top: calc(50px + 1rem);
  margin-top: 1.5rem;
  margin-bottom: calc(3rem + 30px);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.light_title {
  color: #acb4be !important;
}
.expertise h2 {
  font-size: 32px;
  margin: 30px 0;
  top: 50px;
  opacity: 0;
  position: relative;
}
.expertise p {
  font-weight: 500;
  font-size: 1.1em !important;
  color: #777;
  line-height: 26px;
  margin: 0 0 20px;
}
@media (min-width: 1260px) {
  .expertise p {
    max-width: 950px;
  }
}
.cards {
  padding-top: calc(1rem + 120px);
  padding-right: 0.75rem;
  padding-bottom: 3rem;
  padding-left: 0.75rem;
  margin-top: 1.5rem;
  margin-bottom: 3rem;
  display: flex;
  width: 100%;
  box-sizing: border-box;
}
.card {
  box-shadow: 0px 0px 37px -4px rgb(0 0 0 / 10%);
  margin: 0 10px;
  padding: 3rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 450px;
  position: relative;
  top: 50px;
  opacity: 0;
}
.card img {
  width: 100px;
  padding-bottom: 0.25rem;
  margin-bottom: 1.5rem;
  position: relative;
  top: 50px;
  opacity: 0;
}
.card h4 {
  color: #0053ce;
  font-weight: 700;
  font-size: 1.8em;
  margin-bottom: 1rem;
  cursor: pointer;
}
.card p {
  font-size: 1.1em;
  color: #777;
  line-height: 26px;
  margin: 0 0 20px;
  box-sizing: border-box;
}
@media (max-width: 751px) {
  .cards {
    flex-direction: column;
  }
}
@media (max-width: 370px) {
  .card {
    padding-left: 15px;
    padding-right: 15px;
  }
}
