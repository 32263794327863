/* 1185px */
/* 975px */
.contactPage {
  display: flex;
}
.contact_details {
  padding: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #212529 !important;
  color: #f2f2f2;
  flex: 0.35;
  padding-bottom: 6rem;
}
.contact_details .address {
  display: flex;
  padding: 3rem;
  padding-bottom: 2rem;
  border-bottom: 2px solid #777;
  position: relative;
  top: 50px;
  opacity: 0;
}
.contact_details .address .MuiSvgIcon-root {
  font-size: 6em;
  color: #dbdfde;
  margin-right: 20px;
}
.contact_details h2 {
  margin-bottom: 20px;
  padding-top: 5px;
}
.contact_details p {
  line-height: 26px;
}
@media (max-width: 1185px) {
  .contact_details .address {
    flex-direction: column;
  }
}
.companyDetails {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-left: 6rem;
  padding-right: 6rem;
  padding-bottom: 30px;
  border-bottom: 2px solid #777;
  position: relative;
  top: 50px;
  opacity: 0;
}
.contact_mail {
  padding-left: 0.9rem;
  padding-right: 0.9rem;
}
.companyDetails p {
  padding-top: 30px;
  color: #777;
}
.companyDetails a {
  color: #fff;
  text-decoration-color: transparent;
  font-size: 1.5rem;
  transition: 0.3s;
}
.companyDetails a:hover {
  text-decoration-color: #fff;
}
/* form */
.contact_form {
  flex: 0.65;
  padding: 30px;
  background: #f2f2f2;
}
.form_intro {
  text-align: center;
  padding-bottom: 5rem;
  position: relative;
  top: 50px;
  opacity: 0;
}
.allForm {
  position: relative;
  top: 100px;
  opacity: 0;
}
.form_intro p {
  padding-top: 10px;
  color: #444;
}
.form-group.required label {
  color: #f88;
}
.form-two-group {
  display: flex;
  justify-content: space-between;
}
.form-group {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-right: 20px;
  margin-bottom: 20px;
}
.form-group label {
  margin: 10px 0;
}
.form-group input {
  padding: 1rem 1.5rem;
  outline: none;
  border: none;
  background: #fff;
  margin-bottom: 10px;
  box-shadow: 0px 0px 37px -4px rgb(0 0 0 / 10%);
}
textarea {
  min-height: 300px;
  outline: none;
  border: none;
  padding: 1rem 1.5rem;
  /* min-width: 700px; */
}
.form-group input::placeholder {
  color: #aaa;
  font-size: 14px;
  font-weight: 500;
}

@media (max-width: 975px) {
  .contactPage {
    flex-direction: column;
  }
  .form-two-group {
    flex-direction: column;
  }
}
