.customerCare {
  padding-left: 6rem;
  padding-right: 6rem;
  padding-top: 6rem;
}
@media (max-width: 730px) {
  .customerCare {
    padding-left: 0;
    padding-right: 0;
  }
  .fullCare {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
.fullCare {
  display: flex;
  margin-bottom: 6rem;
}
.fullCare p {
  color: #777;
  margin-bottom: 3rem;
  line-height: 25px;
  margin-bottom: 3rem;
}
.mainCare {
  margin-right: 3rem;
}
.mainCare h2 {
  color: #212529;
  font-weight: 700;
  line-height: 1.2;
  font-size: 2.3em;
  margin-bottom: 3rem;
  position: relative;
  top: 50px;
  opacity: 0;
}
.mainCare > p {
  position: relative;
  top: 50px;
  opacity: 0;
}
.sideCare {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}
.sideCare > div {
  position: relative;
  top: 50px;
  opacity: 0;
}
.sideCare h4 {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 1.5em;
}
.sideCare p {
  padding-left: 65px;
}
@media (max-width: 1015px) {
  .fullCare {
    flex-direction: column;
  }
  .sideCare {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
