.footer {
  background-color: #0053ce !important;
  position: relative;
  font-size: 0.9em;
  line-height: 26px;
  clear: both;
  color: #fff;
}
.footer_info {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  padding-top: 3rem;
}
.footer_info h2 {
  text-align: center;
  margin-bottom: 1.5rem;
}
.footer_info ul li {
  margin-bottom: 10px;
}
.footer_info ul li,
.footer_info ul li a {
  list-style: none;
  color: #a3b6e7;
  text-decoration: none;
  text-align: center;
  transition: all 0.3s linear;
}
.footer_info ul li:hover,
.footer_info ul li a:hover {
  color: #fff;
}
.footer_info ul li .MuiSvgIcon-root {
  padding: 10px;
  background-color: transparent;
  border-radius: 50%;
  transition: all 0.3s linear;
}
.footer_info ul li:hover .MuiSvgIcon-root {
  background-color: #00000066;
}
.copyRights {
  /* text-align: center; */
  /* width: 100%; */
  padding-right: 3rem;
  padding-left: 3rem;
  margin-right: auto;
  margin-left: auto;
  padding-top: 1.5rem !important;
  padding-bottom: 3rem !important;
  box-sizing: border-box;
}
hr {
  border-color: #ffffff60;
}
.copyRights p {
  /* border-top: 1px solid #ffffff60; */
  padding-top: 2rem;
  text-align: center;
  /* margin-left: 75px; */
  /* margin-right: auto; */
}
@media (max-width: 746px) {
  .footer_info {
    flex-direction: column;
  }
  /* .copyRights p {
    margin-left: 0;
  } */
}
@media (min-width: 746px) {
  .copyRights p {
    margin-left: 75px;
    color: #a3b6e7;
    transition: 0.3s;
  }
  .copyRights p:hover {
    color: #fff;
  }
}
