/* @import url("https://fonts.googleapis.com/css2?family=Kufam:wght@600&display=swap"); */
.header {
  display: flex;
  align-items: center;
  background-color: #fff;
  position: sticky;
  color: #444444;
  height: 80px;
  top: 0;
  transition: all 0.3s linear;
  padding-top: 20px;
  padding-bottom: 20px;
  z-index: 12;
  justify-content: space-between;
  padding-right: 3rem;
  padding-left: 3rem;
}
@media (max-width: 432px) {
  .header {
    padding-left: 1rem;
  }
  .logo {
    margin-right: 10px;
  }
}
@media (max-width: 300px) {
  .header {
    padding-left: 0rem;
    padding-right: 1rem;
  }
}
.header_scrolled {
  padding-top: 0;
  padding-bottom: 0;
  box-shadow: 0 5px 5px 0 #ddd;
}
.logo {
  width: 250px;
  margin-left: 1.5rem;
}
.logo_container {
  width: 200px;
}
.logo_container img {
  width: 100%;
  object-fit: contain;
}

/* header Items */
.header_items {
  display: flex;
  list-style: none;
  flex: 0.6;
  justify-content: space-between;
  margin-right: 3rem;
}
.header_item {
  cursor: pointer;
  font-weight: 500;
  position: relative;
  padding-bottom: 5px;
}
.header_item::after {
  content: "";
  width: 000%;
  height: 3px;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: all 0.3s linear;
  background: #444;
}
.header_item.aboutus:nth-of-type(1):after {
  width: 100%;
}
.header_item.services:nth-of-type(2):after {
  width: 100%;
}
.header_item.careers:nth-of-type(3):after {
  width: 100%;
}
.header_item.contact:nth-of-type(4):after {
  width: 100%;
}
.header_item a {
  text-decoration: none;
  color: #444;
}
.header_item ul {
  list-style: none;
  /* padding-top: 50px; */
  position: absolute;
  /* bottom: 140px; */
  left: 0;
  opacity: 0;
  transform: scale(0);
  min-width: 200px;
  background: #fff;
  font-weight: 300;
  transition: all 0.5s ease-in-out;
  z-index: -1;
}
.header_item ul.showen {
  opacity: 1;
  top: 100%;
  transform: scale(1);
  border: 1px solid #ccc;
}
.header_item ul li {
  /* margin-bottom: 10px; */
  padding: 10px 20px;
  color: #777;
  font-size: 12px;
  font-weight: bold;
  background-color: #fff;
  transition: all 0.3s linear;
}
.header_item ul li:hover {
  background-color: #f2f2f2;
}
.header_item ul a {
  text-decoration: none !important;
}
@media (min-width: 1100px) {
  .header_items {
    justify-content: flex-end;
  }
  .header_item {
    margin-left: 30px;
    font-size: 12px;
    font-weight: bold;
  }
  .header_item ul li:first-of-type {
    padding-top: 25px;
  }
  .header_item ul li:last-of-type {
    padding-bottom: 25px;
  }
}
@media (max-width: 1030px) {
  .header_item,
  .header_item a {
    color: #0053ce;
  }
  .header_items {
    flex-direction: column;
    flex: none;
    position: absolute;
    top: -300px;
    z-index: -1;
    background: #fff;
    left: 0;
    width: 100%;
    transition: all 0.2s linear;
  }
  .header_item {
    /* margin-bottom: 20px; */
    padding: 10px 0;
    padding-left: 3rem;
    padding-right: 10px;
    border-bottom: 1px solid #eee;
  }
  .header_item::after {
    width: 0 !important;
  }
  .header_items .header_item ul {
    all: unset;
    list-style: none;
    /* transform: scale(0) !important; */
    /* height: 0px !important; */
    display: none;
    transition: all 0.3s linear;
  }
  .showforMobile {
    display: block !important;
    height: 200px;
  }
  .header_item .allSpan {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .allSpan .MuiSvgIcon-root {
    font-size: 2rem;
  }
  .header .menuBtn {
    font-size: 2rem;
    cursor: pointer;
    color: #fff;
    background: #0053ce;
    padding: 5px 10px;
  }
  .header_items.show {
    top: 100px;
    z-index: 9;
  }
  .logo {
    position: relative;
    z-index: 2;
  }
  /* special btn */
  .menuPlanService .allSpan {
    background-color: #0053ce;
    color: #fff !important;
    box-sizing: border-box;
    margin-left: -10px;
    padding-left: 10px;
  }
  .menuPlanService .allSpan a {
    color: #fff;
  }
}
@media (max-width: 288px) {
  .logo {
    margin-left: 0;
  }
}
