.yellowStripe {
  position: relative;
  width: 50px;
  height: 5px;
  display: inline-block;
  margin-right: 10px;
  margin-left: 5px;
}
.yellowStripe::after {
  content: "";
  position: absolute;
  top: -5px;
  left: 0;
  width: 50px;
  border-top: 5px solid #fbe91b;
  /* transform: translate3d(0, -50%, 0); */
  box-sizing: border-box;
}
