.aboutUs {
  padding-top: 4.6153846154rem;
  padding-left: 6rem;
  padding-right: 6rem;
}
.about {
  display: flex;
  justify-content: space-between;
}
.about_info {
  margin-right: 3rem;
  position: relative;
  top: 100px;
  opacity: 0;
}
.about_info p {
  font-size: 16px;
  padding-bottom: 0.5rem;
  margin-bottom: 1.5rem;
  color: #777;
  line-height: 26px;
  max-width: 90%;
}
.about_imgCont {
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}
.about_imgCont > img {
  max-width: 100%;
  object-fit: contain;
  position: relative;
  top: -10px;
  left: -50px;
  opacity: 0;
}
@media (max-width: 1242px) {
  .about {
    flex-direction: column;
  }
  .about_info p {
    max-width: 100%;
  }
}
@media (max-width: 418px) {
  .aboutUs {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
/* our goals styles */
.about_features {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.about_goal {
  margin-right: 20px;
  max-width: 300px;
  padding: 2rem;
  box-shadow: 0px 0px 37px -4px rgb(0 0 0 / 10%);
}
.about_goal img {
  width: 100%;
  object-fit: contain;
  padding-bottom: 0.5rem;
  margin-bottom: 1.5rem;
}
.about_goal {
  font-size: 1.35em;
  font-family: "Lora", sans-serif;
  line-height: 1.4;
  padding-bottom: 2rem;
  margin-bottom: 1.5rem;
  color: #777;
  min-height: 600px;
  position: relative;
  top: 50px;
  opacity: 0;
}
