.headline_cont {
  color: #fff;
  background: #0053ce;
  padding-top: calc(5px + 3rem);
  padding-bottom: calc(5px + 3rem);
  padding-left: 6rem;
  /* margin: 0 0 35px 0; */
}
.headline {
  font-size: 4.5em;
  font-weight: 800;
  color: #fff;
  display: inline-block;
  line-height: 1;
  margin: 0;
  padding: 0;
  position: relative;
  top: 1px;
  letter-spacing: -0.05em;
  box-sizing: border-box;
  left: -50px;
  opacity: 0;
}
@media (max-width: 490px) {
  .headline_cont {
    padding-left: 0.5rem;
  }
  .headline {
    font-size: 2.5em;
  }
}
