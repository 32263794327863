.btntotop {
  position: fixed;
  bottom: -100px;
  right: 10px;
  height: 9px;
  width: 50px;
  display: grid;
  place-items: center;
  background: #404040;
  padding: 20px 10px 30px;
  /* text-align: center; */
  text-decoration: none;
  color: #fff;
  transition: all 0.3s;
  opacity: 0;
  z-index: 1040;
  cursor: pointer;
}
.showIt {
  opacity: 0.75;
  bottom: 0;
}
.btntotop:hover {
  opacity: 1;
}

.btntotop .MuiSvgIcon-root {
  font-size: 2em;
}
.btntotop:hover .MuiSvgIcon-root {
  animation: toTop 1s infinite linear;
}
@keyframes toTop {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(00px);
  }
  75% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(00px);
  }
}
