.careersPage {
  padding-right: 6rem;
  padding-left: 6rem;
}
.culture {
  width: 100%;
  display: flex;
  margin-bottom: 6rem;
  position: relative;
  left: -100px;
  opacity: 0;
}
.careers_expertise h2,
.careers_expertise p {
  position: relative;
  top: 50px;
  opacity: 0;
}
@media (min-width: 1260px) {
  .careers_expertise p {
    max-width: 950px;
  }
}
.career_imgCont {
  margin-right: 3rem;
  flex: 0.5;
  position: relative;
}
.career_imgCont img {
  width: 100%;
  object-fit: contain;
}
.career_imgCont .career_topImg {
  position: absolute;
  width: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.career_cultureInfo {
  flex: 0.5;
}
.career_cultureInfo h2 {
  color: #212529;
  font-weight: 700;
  margin-bottom: 1.5rem;
  line-height: 42px;
  font-size: 2.2em;
}
.career_cultureInfo p {
  font-size: 1.1em;
  margin-bottom: 1.5rem;
  color: #777;
  line-height: 26px;
}
@media (max-width: 1260px) {
  .culture {
    flex-direction: column;
  }
  .career_cultureInfo {
    flex: auto;
  }
  .career_imgCont {
    margin-bottom: 3rem;
    margin-right: 0rem;
    flex: auto;
  }
}
/* offer */
.offer {
  padding: 4.6153846154rem 6rem;
  background-color: #212529 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.details {
  flex: 0.6;
}
.details h4 {
  color: #e0e1e5;
  font-weight: 300;
  font-size: 1.5em;
  margin-bottom: 1rem;
  position: relative;
  top: 50px;
}
.details h2 {
  color: #fff;
  font-weight: 800;
  font-size: 2.75em;
  letter-spacing: -1px;
  padding-right: 1rem;
  margin-bottom: 1rem;
  position: relative;
  top: 50px;
  opacity: 0;
}
.details p {
  color: #bfbfbf;
  font-size: 1.2em;
}
.goto {
  flex: 0.4;
}
.goto button {
  min-width: 150px;
  padding: 10px;
  background: transparent;
  border: 4px solid #fbe91b;
  border-radius: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  transition: all 0.3s linear;
  cursor: pointer;
  position: relative;
  left: -100px;
  opacity: 0;
}
.goto button .MuiSvgIcon-root {
  color: #777;
  padding-left: 5px;
  transition: all 0.3s linear;
}
.goto button:hover {
  background: #fbe91b;
  color: #000;
}
.goto button:hover .MuiSvgIcon-root {
  color: #000;
  padding-left: 15px;
}
@media (max-width: 1008px) {
  .offer {
    flex-direction: column;
  }
  .details h2 {
    font-size: 1.7em;
  }
  .details {
    margin-bottom: 3rem;
    flex: auto;
  }
  .goto {
    flex: auto;
  }
  .careersPage {
    padding-right: 0rem;
    padding-left: 0rem;
  }
  .careers_expertise p,
  .career_cultureInfo p {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
